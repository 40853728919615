import type * as schema from '@getinsomnia/api-client/schema';
import * as util from '~/lib/fetch';
import type { PrivateKeyParams } from './e2ee.client';
import { loadSrp } from './srp.client';

export class NeedPassphraseError extends Error {
  constructor() {
    super('Passphrase required');

    // This trick is necessary to extend a native type from a transpiled ES6 class.
    Object.setPrototypeOf(this, NeedPassphraseError.prototype);
  }
}

export class InvalidPassphraseError extends Error {
  constructor() {
    super('Invalid password');

    // This trick is necessary to extend a native type from a transpiled ES6 class.
    Object.setPrototypeOf(this, InvalidPassphraseError.prototype);
  }
}

/**
 * Performs an SRP login. When useCookies is set to false, the server uses the
 * negotiated SRP K value to create a valid session token. When useCookies is
 * set to true, the SRP K value is discarded and a pseudo-random session cookie
 * is created upon login instead, using HTTP-only mode.
 *
 * authSecret never needs to be passed; it is only passed by other auth
 * functions when the authSecret value has already been computed for another
 * reason (such as during signup.)
 *
 * useCookies needs to be set to false if the client needs access to a valid
 * session token.
 *
 * @param rawEmail The raw e-mail identity.
 * @param rawPassphrase The raw passphrase.
 * @param authSecret If already calculated, the derived passphrase key.
 * @param useCookies If true, the server creates a psuedo-random session cookie.
 * @returns The SRP K value.
 */
export async function login(
  rawEmail: string,
  rawPassphrase: string,
  encDriverKey: string,
  authSecret: string | null = null,
  useCookies = true,
) {
  const { Client, Buffer, params, deriveKey, srpGenKey } = await loadSrp();
  // ~~~~~~~~~~~~~~~ //
  // Sanitize Inputs //
  // ~~~~~~~~~~~~~~~ //

  const email = sanitizeEmail(rawEmail);
  const passphrase = sanitizePassphrase(rawPassphrase);

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //
  // Fetch Salt and Submit A To Server //
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //

  const { saltKey, saltAuth } = await util.post<{
    saltKey: string;
    saltAuth: string;
  }>('/auth/web-login-s', { email });

  // marckong: we are passing encDriverKey value here instead of email as we allow primary email to be changed
  authSecret = authSecret || (await deriveKey(passphrase, encDriverKey, saltKey));

  const secret1 = await srpGenKey();

  const c = new Client(
    params[2048],
    Buffer.from(saltAuth, 'hex'),
    Buffer.from(encDriverKey, 'utf8'),
    Buffer.from(authSecret, 'hex'),
    Buffer.from(secret1, 'hex'),
  );

  const srpA = c.computeA().toString('hex');

  const { sessionStarterId, srpB } = await util.post<{
    sessionStarterId: string;
    srpB: string;
  }>('/auth/web-login-a', { srpA, email });

  // ~~~~~~~~~~~~~~~~~~~~~ //
  // Compute and Submit M1 //
  // ~~~~~~~~~~~~~~~~~~~~~ //

  c.setB(new Buffer(srpB, 'hex'));
  const srpM1 = c.computeM1().toString('hex');
  const { srpM2 } = await util.post<{
    srpM2: string;
  }>('/auth/web-login-m1', {
    srpM1,
    sessionStarterId,
    useCookies,
  });

  // ~~~~~~~~~~~~~~~~~~~~~~~~~ //
  // Verify Server Identity M2 //
  // ~~~~~~~~~~~~~~~~~~~~~~~~~ //

  c.checkM2(new Buffer(srpM2, 'hex'));

  // Return K
  return c.computeK().toString('hex');
}

export type EncryptionKeys = {
  verifier: string;
  publicKey: string;
  encPrivateKey: string;
  encSymmetricKey: string;
  saltAuth: string;
  saltEnc: string;
  saltKey: string;
};

export async function createPassphrase(rawEmail: string, rawPassphrase: string) {
  const {
    Buffer,
    params,
    getRandomHex,
    deriveKey,
    generateKeyPairJWK,
    generateAES256Key,
    computeVerifier,
    encryptAES,
  } = await loadSrp();

  const email = sanitizeEmail(rawEmail);
  const passphrase = sanitizePassphrase(rawPassphrase);
  const saltEnc = await getRandomHex();
  const saltAuth = await getRandomHex();
  const saltKey = await getRandomHex();

  // Generate some secrets for the user base'd on password
  const authSecret = await deriveKey(passphrase, email, saltKey);
  const derivedSymmetricKey = await deriveKey(passphrase, email, saltEnc);

  // Generate public/private keypair and symmetric key for Account
  const { publicKey: publicKeyObj, privateKey } = await generateKeyPairJWK();
  const symmetricKeyJWK = await generateAES256Key();

  // Compute the verifier key and add it to the Account object
  const verifier = computeVerifier(
    params[2048],
    Buffer.from(saltAuth, 'hex'),
    Buffer.from(email, 'utf8'),
    Buffer.from(authSecret, 'hex'),
  ).toString('hex');

  // Encode keypair
  const encSymmetricJWKMessage = encryptAES(derivedSymmetricKey, JSON.stringify(symmetricKeyJWK));
  const encPrivateJWKMessage = encryptAES(symmetricKeyJWK, JSON.stringify(privateKey));

  // Add keys to account
  const publicKey = JSON.stringify(publicKeyObj);
  const encPrivateKey = JSON.stringify(encPrivateJWKMessage);
  const encSymmetricKey = JSON.stringify(encSymmetricJWKMessage);

  return {
    verifier,
    publicKey,
    encPrivateKey,
    encSymmetricKey,
    saltAuth,
    saltEnc,
    saltKey,
  } as EncryptionKeys;
}

export async function deriveSymmetricKey(params: PrivateKeyParams, rawPassphrase: string) {
  const { deriveKey } = await loadSrp();

  const passPhrase = sanitizePassphrase(rawPassphrase);
  const { encDriverKey, saltEnc } = params;

  return (await deriveKey(passPhrase, encDriverKey, saltEnc)) as string;
}

export async function githubOauthConfig() {
  return util.get<schema.GithubOAuthConfigResponse>('/v1/oauth/github/config', false);
}

export async function githubAppConfig() {
  return util.get<{ clientID: string; appName: string }>('/v1/github-app/config', false);
}

function sanitizeEmail(email: string) {
  return email.trim().toLowerCase();
}

export function sanitizePassphrase(passphrase: string) {
  return passphrase.trim().normalize('NFKD');
}

const APP_TOKEN_BOX_KEY = 'APP_TOKEN_BOX_KEY';

export function getTokenBox() {
  return sessionStorage.getItem(APP_TOKEN_BOX_KEY);
}

export function unsetTokenBox() {
  return sessionStorage.removeItem(APP_TOKEN_BOX_KEY);
}

export async function getKeyPair(): Promise<{
  publicKey: JsonWebKey;
  privateKey: JsonWebKey;
}> {
  const { generateKeyPairJWK } = await loadSrp();

  return await generateKeyPairJWK();
}
